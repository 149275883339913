import { Box, ChevronLeftIcon, ChevronRightIcon, IconButton } from '@playful/design_system';
import React, { Children, useEffect, useState } from 'react';

export interface PaginatedCarousel {
  pageSize: number;
  children: JSX.Element[];
  eachSize: number;
  gap: number;
  _key?: string;
}

export function PaginatedCarousel({
  children,
  pageSize,
  eachSize: _eachSize,
  _key,
  gap,
}: PaginatedCarousel) {
  const eachSizeMin = 200;
  const eachSizeMax = 300;
  const eachSize = Math.min(Math.max(_eachSize, eachSizeMin), eachSizeMax);
  const childrenArray = Children.toArray(children);
  const [page, setPage] = useState(0);
  const numberOfPages = Math.ceil(children.length / pageSize);
  const left = page === 0 ? 0 : page * (eachSize + gap) * pageSize * -1;

  useEffect(() => {
    setPage(0);
  }, [_key]);

  return (
    <Box w='100%'>
      <Box
        pos={'absolute'}
        w='100%'
        overflowX={{ base: 'auto', lg: 'clip' }}
        overflowY='visible'
        left={0}
        right={0}
        zIndex={'sticky'}
      >
        <Box
          w={(eachSize + gap) * children.length}
          left={{ base: '0px', lg: left + 'px' }}
          pos={'relative'}
          transition='left 0.8s'
          overflow={eachSize > 0 ? 'visible' : 'hidden'}
          whiteSpace='nowrap'
        >
          {Children.map(childrenArray, (child) => (
            <Box display='inline-block' w={eachSize} mr={`${gap}px`}>
              {child}
            </Box>
          ))}
        </Box>
      </Box>
      <Box pos='relative'>
        <IconButton
          aria-label='previous templates'
          aria-hidden='true'
          size='lg'
          icon={<ChevronLeftIcon />}
          pos='absolute'
          top={'calc(50% - 24px)'}
          left={'-24px'}
          zIndex={'sticky'}
          shadow={'md'}
          _focus={{ shadow: 'md' }}
          colorScheme='white'
          variant='solid'
          onClick={() => {
            setPage(page - 1);
          }}
          hidden={pageSize === 2 || page === 0}
        />
        <IconButton
          aria-label='previous templates'
          aria-hidden='true'
          size='lg'
          icon={<ChevronRightIcon />}
          pos='absolute'
          top={'calc(50% - 24px)'}
          right={'-24px'}
          zIndex={'sticky'}
          shadow={'md'}
          _focus={{ shadow: 'md' }}
          colorScheme='white'
          variant='solid'
          onClick={() => {
            setPage(page + 1);
          }}
          hidden={pageSize === 2 || page + 1 === numberOfPages}
        />

        <Box visibility='hidden' w={eachSize}>
          {children[0]}
        </Box>
      </Box>
    </Box>
  );
}
