import { Skeleton } from '@chakra-ui/react';
import { Box, MotionBox, ThumbnailPreview, breakpoints } from '@playful/design_system';
import { ProjectInfo } from '@playful/runtime';
import times from 'lodash/times';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { PaginatedCarousel } from '../PaginatedCarousel';
import type { TemplateCategoryProject } from './types';

export type TemplateShowcaseProps = Pick<PaginatedCarousel, '_key'> & {
  isCarousel?: boolean;
  renderCard: (props: { info: ProjectInfo; videoUrl?: string }) => React.ReactElement;
  projects?: TemplateCategoryProject[];
  projectInfos?: Record<string, ProjectInfo>;
};

export function TemplateShowcase({
  _key = '*',
  isCarousel,
  projects,
  projectInfos = {},
  renderCard: Card,
}: TemplateShowcaseProps) {
  const [containerWidth, setContainerWidth] = useState(0);

  // How many thumbnails to fit on a row
  const pageSize = useMemo(() => {
    if (containerWidth < breakpoints.lg) return 2;
    if (containerWidth < breakpoints.xl) return 4;
    if (containerWidth < breakpoints['2xl']) return 4;
    return 5;
  }, [containerWidth]);

  // Gap in between thumbnails
  const gap = useMemo(() => {
    if (containerWidth < breakpoints.md) return 16;
    return 24;
  }, [containerWidth]);

  const eachSize = useMemo(
    () => (containerWidth - (pageSize - 1) * gap) / pageSize,
    [pageSize, containerWidth, gap],
  );

  const containerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const width = entry.contentRect.width;
        setContainerWidth(width);
      }
    });
    const container = containerRef.current;
    if (container) {
      observer.observe(container);
    }

    return () => {
      if (container) {
        observer.unobserve(container);
      }
    };
  }, []);

  return (
    <MotionBox
      ref={containerRef}
      w='100%'
      position='relative'
      initial={{ top: '-16px', opacity: 0 }}
      animate={{ top: '0px', opacity: 1 }}
      transition={{
        type: 'spring',
        mass: 1,
        stiffness: 100,
        damping: 35,
      }}
    >
      {isCarousel ? (
        <PaginatedCarousel pageSize={pageSize} _key={_key} gap={gap} eachSize={eachSize}>
          {!projects
            ? times(pageSize, (idx) => (
                <ThumbnailPreview
                  key={idx}
                  Thumbnail={<Skeleton width={eachSize} height='100%' />}
                />
              ))
            : projects?.map((p) => (
                <Card key={p.id} info={projectInfos[p.id]} videoUrl={p.videoUrl} />
              ))}
        </PaginatedCarousel>
      ) : (
        <Box display='flex' gap={`${gap}px`} width='100%' flexWrap='wrap'>
          {!projects
            ? times(pageSize, (idx) => (
                <Box width={eachSize} key={idx}>
                  <ThumbnailPreview Thumbnail={<Skeleton width={eachSize} height='100%' />} />
                </Box>
              ))
            : projects.map((p) => {
                return (
                  <Box width={eachSize} key={p.id}>
                    <Card info={projectInfos[p.id]} videoUrl={p.videoUrl} />
                  </Box>
                );
              })}
        </Box>
      )}
    </MotionBox>
  );
}
