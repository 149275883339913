import { sanityFetch } from '@playful/frontend/sanity';
import { fromPromise } from '@playful/utils';
import { getProjectsByIdBatch } from '@playful/workbench/api/projects';
import groq from 'groq';
import shuffle from 'lodash/shuffle';

import type { TemplatesPageProps, TutorialProjectData, TutorialsData } from './types';

type TemplatesQuery = TutorialsData & {
  title: string;
  interactivesTitle: string;
  interactives: {
    id: string; // project id
    videoUrl?: string;
  }[];
  categoriesTitle: string;
  categories: {
    title: string;
    slug: string;
    projects: {
      id: string; // project id
      videoUrl?: string;
    }[];
  }[];
};

export const getTutorialsFrag = groq`
tutorialsTitle,
tutorials[] {
  _key,
  _type,
  title,
  callout,
  content,
  _type == 'tutorialProject' => {
    'project': project->{
      'id': project.id,
      'slug': project.slug,
      'previewVideoUrl': previewVideo.asset->url,
    },
  },
  _type == 'tutorialVideo' => {
    video {
      poster,
      'mediaUrl': media.asset->url,
    }
  }
}`;

const getTemplatesPageQuery = groq`
*[_id == "templatesPage"][0] {
  ${getTutorialsFrag},
  title,
  interactivesTitle,
  'interactives': interactives[] -> {
    'id': project.id,
    'videoUrl': previewVideo.asset->url,
  },
  categoriesTitle,
  categories[] {
    title,
    'slug': slug.current,
    'projects': projects[] -> {
      'id': project.id,
      'videoUrl': previewVideo.asset->url,
    }
  }
}`;

export async function getTemplatesContentFromSanity(
  draftMode?: boolean,
): Promise<TemplatesPageProps> {
  const pageData = await sanityFetch<TemplatesQuery>(getTemplatesPageQuery, {}, { draftMode });
  const categories = pageData.categories.map((c) => ({
    title: c.title,
    slug: c.slug,
    projects: c.projects,
  }));

  // add in interactive projects
  const interactives = {
    title: pageData.interactivesTitle,
    slug: 'interactives',
    projects: shuffle(pageData.interactives), // interactive projects should be in a random order
  };

  // Make a map of all projects from all categories
  const allCategoryProjects = Object.fromEntries(
    [...categories.flatMap((c) => c.projects), ...interactives.projects].map((p) => [p.id, p]),
  );

  const tutorialProjects =
    pageData.tutorials?.filter<TutorialProjectData>(
      (t): t is TutorialProjectData => t._type === 'tutorialProject',
    ) ?? [];

  const allCategoryKeys = Object.keys(allCategoryProjects);

  // Fetch all the project infos in one batch
  const [, batchedInfos = []] = await fromPromise(
    getProjectsByIdBatch([...allCategoryKeys, ...tutorialProjects.map((t) => t.project.id)]),
  );

  // Make a map of ProjectId -> ProjectInfo
  const allCatInfosMap = Object.fromEntries(batchedInfos.map((p) => [p.id, p]) ?? []);

  // All templates should be the first option
  categories.unshift({
    title: 'all templates',
    slug: 'all',
    projects: Object.values(allCategoryProjects).sort((a, b) => {
      const aInfo = allCatInfosMap[a.id];
      const bInfo = allCatInfosMap[b.id];
      return bInfo?.created - aInfo?.created;
    }),
  });

  return {
    title: pageData.title,
    categoriesTitle: pageData.categoriesTitle,
    categories: categories.filter((c) => c.projects.length > 0),
    interactives,
    projectInfos: allCatInfosMap,
    tutorialsData: {
      tutorialsTitle: pageData.tutorialsTitle,
      tutorials: pageData.tutorials,
    },
  };
}
