import { Tab, TabList } from '@playful/design_system';
import { formatEventString, triggerCustomEvent } from '@playful/telemetry';
import React, { useEffect, useRef, useState } from 'react';

import { HEADER_HEIGHT } from '../../layouts/MainLayout';
import { useProjectTemplates } from '../ProjectTemplates';
import type { TemplateCategory } from './types';

export type StickyHeaderProps = {
  isSticky?: boolean;
  categories: TemplateCategory[];
  wrap?: boolean;
};

export const StickyHeader = ({ isSticky, categories, wrap = false }: StickyHeaderProps) => {
  const { containerPadding } = useProjectTemplates();
  const stickyBarObserver = useRef<IntersectionObserver>();
  const stickyBarRef = useRef<HTMLDivElement>();
  const [stickyIsActive, setStickyIsActive] = useState(false);

  useEffect(() => {
    const observer = stickyBarObserver.current;
    const stickyEl = stickyBarRef.current;

    return () => {
      if (observer && stickyEl) observer.unobserve(stickyEl);
    };
  }, []);

  // TODO: Tabs scroll
  return (
    <>
      <div
        ref={(el: HTMLDivElement) => {
          if (el && isSticky) {
            stickyBarRef.current = el;
            stickyBarObserver.current = new IntersectionObserver(
              ([e]) => setStickyIsActive(!e.isIntersecting),
              { threshold: [1], rootMargin: `-${HEADER_HEIGHT}px 0px 0px 0px` },
            );
            stickyBarObserver.current.observe(el);
          }
        }}
      />
      <TabList
        position={isSticky ? 'sticky' : 'static'}
        top={containerPadding.top + 'px'}
        left='0'
        zIndex={isSticky && stickyIsActive ? 'sticky' : 'auto'}
        backgroundColor='white'
        py={4}
        boxShadow={stickyIsActive ? 'md' : 'none'}
        mx={stickyIsActive ? '-' + containerPadding.left + 'px' : 0}
        pl={stickyIsActive ? containerPadding.left + 'px' : 0}
        overflowX='auto'
        flexWrap={wrap ? 'wrap' : 'nowrap'}
      >
        {categories.map((c) => (
          <Tab
            key={c.title}
            whiteSpace='nowrap'
            onClick={triggerCustomEvent('templatepicker-label-click', {
              name: formatEventString(c.title),
            })}
          >
            {c.title}
          </Tab>
        ))}
      </TabList>
    </>
  );
};
