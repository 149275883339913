import { Text } from '@playful/design_system';
import React from 'react';

import { TemplateShowcase, TemplateShowcaseProps } from './TemplateShowcase';

export function CarouselTemplatePicker({
  title,
  ...props
}: Omit<TemplateShowcaseProps, 'isCarousel'> & {
  title?: string;
}) {
  return (
    <div>
      {title && (
        <Text as='h3' fontWeight='medium' mb={4}>
          {title}
        </Text>
      )}
      <TemplateShowcase isCarousel {...props} />
    </div>
  );
}
