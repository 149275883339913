import { Skeleton } from '@chakra-ui/react';
import { Box, MotionBox, Tabs } from '@playful/design_system';
import React, { PropsWithChildren } from 'react';

import { StickyHeader, StickyHeaderProps } from './StickyHeader';

export type TaggedProjectsTabsProps = PropsWithChildren<{
  onTabChange: (index: number) => void;
  tabIndex: number;
}>;

export function TaggedProjectsHeader({
  categories,
  wrap,
  isSticky,
  isLoading,
}: StickyHeaderProps & {
  isLoading?: boolean;
}) {
  return (
    <MotionBox
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        type: 'spring',
        mass: 1,
        stiffness: 100,
        damping: 35,
      }}
    >
      {isLoading ? (
        <Box display='flex' gap={3} h='60px' alignItems='center'>
          {categories.map((c) => (
            <Skeleton key={c.title} height='14px' width='74px' />
          ))}
        </Box>
      ) : (
        <StickyHeader isSticky={isSticky} categories={categories} wrap={wrap} />
      )}
    </MotionBox>
  );
}

export function TaggedProjectsTabs({ onTabChange, tabIndex, children }: TaggedProjectsTabsProps) {
  return (
    <Tabs
      size={'sm'}
      onChange={(index) => onTabChange(index)}
      variant='solid'
      index={tabIndex}
      isLazy
    >
      {children}
    </Tabs>
  );
}
