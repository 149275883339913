import { Text } from '@playful/design_system';
import { customEvent } from '@playful/telemetry';
import React, { useCallback, useEffect, useState } from 'react';

import { StickyHeaderProps } from './StickyHeader';
import { TaggedProjectsHeader, TaggedProjectsTabs } from './TaggedProjectsTabs';
import { TemplateShowcase, TemplateShowcaseProps } from './TemplateShowcase';
import type { TemplatesPageProps } from './types';

export type UseCaseTemplatePickerProps = {
  category?: string;
  title?: string;
  onCategoryChange?: (category: string) => void;
  origin?: string; // where the picker is used, e.g. 'new-project' or 'home'
} & Pick<TemplateShowcaseProps, 'renderCard' | 'isCarousel'> &
  Pick<StickyHeaderProps, 'isSticky'> &
  Pick<TemplatesPageProps, 'categories' | 'projectInfos'>;
export function UseCaseTemplatePicker({
  category,
  onCategoryChange,
  isCarousel,
  renderCard,
  isSticky,
  origin,
  title,
  categories = [],
  projectInfos,
}: UseCaseTemplatePickerProps) {
  const handleCategoryChange = useCallback(
    (category: string) => {
      onCategoryChange?.(category);
    },
    [onCategoryChange],
  );

  // Filter out categories prefixed with "_" (e.g. _interactives)
  const [tabIndex, setTabIndex] = useState(
    category ? categories.findIndex((cat) => cat.slug === category) : 1,
  );

  const onTabChange = useCallback(
    (index: number) => {
      const category = categories[index];
      setTabIndex(index);
      handleCategoryChange(category.slug);
    },
    [handleCategoryChange, categories],
  );

  useEffect(() => {
    customEvent('templatepicker-load', { location: origin });
  }, [origin]);

  const selectedCategory = categories[tabIndex] ?? {};
  const activeProjects = selectedCategory?.projects ?? [];

  return (
    <div>
      {title && (
        <Text as='h3' fontWeight='medium'>
          {title}
        </Text>
      )}
      <TaggedProjectsTabs onTabChange={onTabChange} tabIndex={tabIndex}>
        <TaggedProjectsHeader
          isLoading={!projectInfos}
          categories={categories}
          isSticky={isSticky}
        />
        <TemplateShowcase
          isCarousel={!!isCarousel}
          renderCard={renderCard}
          projects={activeProjects}
          projectInfos={projectInfos}
          _key={selectedCategory.title}
        />
      </TaggedProjectsTabs>
    </div>
  );
}
